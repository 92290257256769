<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;"  @click="$router.back()">学员统计</a>
           <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学习记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="operationControl flexdc" style="align-items: flex-start; "> -->
          <div class="searchbox" style="display:flex;padding: 0 13px">
            <div
              title="学员姓名"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员姓名:</span>
             <span>{{$route.query.userName}}</span>
            </div>
            <div
              title="身份证号"
              style="display: flex; align-items: center;margin-left:20px"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel"
                >身份证号:</span
              >
                <span>{{$route.query.idcard}}</span>
            </div>
          </div>
        <!-- </div> -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="课件名称"
                align="left"
                prop="catalogName"
                show-overflow-tooltip
                min-width="200"
              />

              <el-table-column
                label="视频时长"
                align="center"
                show-overflow-tooltip
                prop="kpointDuration"
                min-width="160"
              >
              <template slot-scope="scope">
                  {{getTime(scope.row.kpointDuration)}}
              </template>
              </el-table-column>
              <el-table-column
                label="观看时长"
                align="left"
                prop="timeInterval"
                show-overflow-tooltip
                min-width="160"
              >
               <template slot-scope="scope">
                  {{getTime(scope.row.timeInterval)}}
              </template>
              </el-table-column>
              <el-table-column
                label="开始学习时间"
                align="left"
                prop="startTime"
                show-overflow-tooltip
                min-width="160"
              >
              <template slot-scope="scope">
                  {{scope.row.startTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="结束学习时间"
                align="left"
                prop="endTime"
                show-overflow-tooltip
                min-width="160"
              >
               <template slot-scope="scope">
                  {{scope.row.endTime | moment}}
              </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "", //学员姓名
      idcard: "", //身份证号
    };
  },
  created() {
    this.getareatree();
    this.getstydyStatusList();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    getstydyStatusList() {
      const studyStatusList = this.$setDictionary("STUDY_STATUS", "list") || [];
      let list = [];
      for (const key in studyStatusList) {
        list.push({
          value: key,
          label: studyStatusList[key],
        });
      }
      this.studyStatusList = list;
    },
  
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.$route.query.userId) {
        params.userId = this.$route.query.userId;
      }
      if (this.$route.query.projectId) {
        params.projectId = this.$route.query.projectId;
      }
      this.doFetch({
        url: "/agency-regulation/statistical-user/learningRecord",
        params,
        pageNum,
      });
    },
     getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
</style>
